import React, { useEffect, useState } from 'react'
import { ServicesContext } from "../ServicesProvider";
import { checkIfTokenStillValid } from "../../repositories/utils"

const defaultValues = {}

export const AuthContext = React.createContext(defaultValues)

export const AuthProvider = ({ children, history }) => {
  const { authService } = React.useContext(ServicesContext)

  const [modifiedUsers, setModifiedUsers] = useState([]);

  useEffect(() => {
      checkIfTokenStillValid(history)
  }, [])

  const login = emailFromInput => {
    authService.login(emailFromInput)
  }

  // Return the users passed inside info but replace those that have been modified
  const getUsers = (info) => info?.users?.map(user => modifiedUsers.find((modifiedUser) => modifiedUser?._id?.email === user?._id?.email) || user);

  // Add the user to the cache of modified users
  const modifyUser = (user) => {
    const userEmail = user?._id?.email;
    setModifiedUsers([
        ...modifiedUsers.filter(user => user?._id?.email !== userEmail),
        user,
    ]);
};

  const getAdministrators = () => authService.getAdministrators()

  const getUserInfo = async () => authService.getUserInfo()
  
   return (
    <AuthContext.Provider value={{
      login,
      getUsers,
      modifyUser,
      getAdministrators,
      modifiedUsers,
      getUserInfo
  }}>
      {children}
    </AuthContext.Provider>
  )
}
