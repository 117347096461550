import React, { useEffect, useState } from "react";
import "./UserBookies.styled";
import { NavBarMain, Bookie } from "..";
import { useUser } from "../../hooks";
import {
  BookieContainer, BookieHeaderText, BookieNameBadge, BookiesSubContainer,
  BookiesHeaderContainer, BookiesListContainer, LegendContainer, LegendLink, PageContainer
} from './UserBookies.styled'
import { checkIfTokenStillValid } from "../../repositories/utils";

export const UserBookies = ({history, match}) => {
    const { bookieAction, getBookies } = useUser()
    const [activeBookies, setActiveBookies] = useState({});
    const [bookies, setBookies] = useState(null);

    useEffect(() => {
      getBookies(match.params.email, false).then(res => setBookies(res))
      checkIfTokenStillValid(history)
    }, [])

    const setBookieStatus = (bookie, enabledStatus) => {
      setActiveBookies({...activeBookies, [bookie]: enabledStatus});
    };

    const toggleBookieStatus = async (bookie, userEnabled) => {
      const action = userEnabled ? "disable" : "enable";
      await bookieAction(match.params.email, bookie, action)
      const newStatus = action === "enable";
      setBookieStatus(bookie, newStatus);
    };

    const getAvailableBookies = () => bookies && bookies.filter((bookie) => !bookie.inUse);

    const getUsedBookies = () => bookies?.filter((bookie) => bookie.inUse);

    return (
      <PageContainer>
      <NavBarMain currentPage="bothome" history={history}/>
        <BookieContainer>
        { getAvailableBookies()?.length > 0 && (
          <BookiesSubContainer>
            <BookiesHeaderContainer>
              <BookieHeaderText>Bookies disponibles</BookieHeaderText>
          </BookiesHeaderContainer>
              <BookiesListContainer>
                 {getAvailableBookies()?.map((bookie) => (
                  <Bookie
                    bookies={bookies}
                    bookie={bookie}
                    toggleBookieStatus={toggleBookieStatus}
                    activeBookies={activeBookies}
                    match={match}
                  />
                ))}
              </BookiesListContainer>
              </BookiesSubContainer>
        )}
          {getUsedBookies()?.length > 0 && (
            <BookiesSubContainer>
          <BookiesHeaderContainer>
            <BookieHeaderText>Bookies en uso</BookieHeaderText>
          </BookiesHeaderContainer>
              <BookiesListContainer>
                {getUsedBookies()?.map((bookie) => (
                  <Bookie
                    bookies={bookies}
                    bookie={bookie}
                    type="edit"
                    toggleBookieStatus={toggleBookieStatus}
                    activeBookies={activeBookies}
                    match={match}
                  />
                ))}
              </BookiesListContainer>
            </BookiesSubContainer>
          )}
        </BookieContainer>
      </PageContainer>
    );
  }
;


