import React, { useEffect, useState } from 'react';
import './UserBets.styled';
import { NavBarMain, BetCard } from '..';
import { useUser } from "../../hooks";
import {
  BoldLink, EmailAndLastUpdate, PlacedBetsContainer, RightSide, StyledBadge,
  UserBetsContainer, UserBetsContainerNav, UserEmail, UserEmailText, UserProfit, Subcontainer
} from './UserBets.styled'
import { checkIfTokenStillValid } from "../../repositories/utils";

export const UserBets = ({ history, match }) => {
  const { getUserBets, settleBet, settledBets } = useUser()
  const [userBets, setUserBets] = useState(null)
  const [isPlaced, setIsPlaced] = useState(true);

  useEffect(() => {
    checkIfTokenStillValid(history)
    getUserBets(match.params.email).then(res => setUserBets(res))
  }, [])

  const getEmail = () => (userBets?._id ? userBets._id.email : '');

  const getLastUpdate = () =>
    userBets?.configuration &&
    userBets?.configuration?.proxy &&
    userBets?.configuration.proxy?.lastUpdate &&
    new Date(userBets?.configuration.proxy.lastUpdate).toLocaleString();

  const getProfit = () => userBets?.billing ? Math.round((userBets.billing.profit || 0) * 100) / 100 : 0;

  const getBets = () =>
    isPlaced === true
      ? userBets?.bets?.filter(bet => bet.status === 'PLACED' && !settledBets.some(({ version }) => version === bet.version))
      : userBets?.bets?.filter(bet => ['SETTLED', 'PAYOUT'].includes(bet.status) || settledBets.some(({ version }) => version === bet.version));

  const getUsername = () =>
    getEmail().includes('@')
      ? getEmail().substring(0, getEmail().lastIndexOf('@')).toUpperCase()
      : getEmail().toUpperCase();

  return (
    <>
      <UserBetsContainer>
        <NavBarMain currentPage="bothome" history={history}/>
        <Subcontainer>
          <UserEmail>
            <EmailAndLastUpdate>
              <UserEmailText>{getUsername()}</UserEmailText>
              {getLastUpdate() && (
                <div>
                  <BoldLink>Actualizado</BoldLink>{' '}
                  {getLastUpdate()}
                </div>
              )}
            </EmailAndLastUpdate>
            <RightSide>
              <UserProfit>
                  <b>BENEFICIO</b> {getProfit()}€
              </UserProfit>
            </RightSide>
          </UserEmail>
          <UserBetsContainerNav className="nav nav-pills">
            <li className="nav-item">
              <a className={isPlaced ? 'nav-link active' : 'nav-link'} onClick={() => setIsPlaced(true)}>
                ABIERTAS{' '}
                <StyledBadge className="badgeCustom badge badge-light">
                  {
                    userBets?.bets?.filter(bet => bet.status === 'PLACED'  && !settledBets.some(({ version }) => version === bet.version)).length
                  }
                </StyledBadge>
              </a>
            </li>
            <li className="nav-item">
              <a className={isPlaced ? 'nav-link' : 'nav-link active'} onClick={() => setIsPlaced(false)}>
                DETERMINADAS
              </a>
            </li>
          </UserBetsContainerNav>
          <PlacedBetsContainer>
            {getBets()?.map(bet => <BetCard bet={bet} setSettledBet={() => settleBet(getEmail(), bet)}/>)}
          </PlacedBetsContainer>
        </Subcontainer>
      </UserBetsContainer>
    </>
  );
};
