import styled from "styled-components";

export const UserBetsContainer = styled.div`
  width: 100%;
  padding: 20px 50px 50px 50px;
  font-family: 'Montserrat';

  @media only screen and (max-width: 700px) {
    padding: 20px 10px 10px 10px;
  }
`

export const Subcontainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1600px;
  width: 100%;
  margin: auto;
  margin-top: 0px;

  @media (max-width: 768px) {
      flex-direction: column;
      align-items: left;
    }
`;

export const UserEmail = styled.div`
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 700px) {
    padding-right: 5px;
    padding-left: 5px;
    padding-top: 5px;
    display: flex;
    justify-content: space-between;
		flex-direction: column;
    align-items: flex-start;
	  margin: 0px;
  }
`

export const EmailAndLastUpdate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
`

export const UserEmailText = styled.div`
  font-size: 25px;

  @media only screen and (max-width: 700px) {
    font-size: 18px;
    width: fit-content;
    text-align: center;
  }
`

export const BoldLink = styled.a`
  font-weight: bold;
`

export const RightSide = styled.div`
  display: flex;
  margin-right: 10px;
  align-items: center;
  align-self: flex-end;

  @media only screen and (max-width: 700px) {
    align-self: flex-start;
  }
`

export const UserProfit = styled.div`
  margin-right: 10px;

  @media only screen and (max-width: 700px) {
    position: unset;
    right: 0;
    margin-right: 2px;
  }
`

export const UserBetsContainerNav = styled.div`
  margin-top: 5px;
  flex-wrap: nowrap !important;
  border-radius: 5px;
  background: #1C2D1C;
  width: fit-content;

  > .userEmail {
    align-items: center;
    margin-bottom: 20px;
  }

  > .leftSide {
    margin-left: 0px !important;
  }

  > .nav-item{
    cursor: pointer;
  }

  > .nav-item > .nav-link:not(.active) {
    :hover {
    color: white !important;
    }
  }

  .active {
    background-color: #E9FD8C !important;
    color: #1d2d1d !important;
    font-weight: bold;
  }

  :hover {
    font-weight: bold;
    border-radius: 5px;
  }

  .badge {
    background-color: #1d2d1d !important;
    color: #E9FD8C !important;
    font-weight: bold;
    padding: 0px 10px;
    font-size: 12px;
  }
`

export const StyledBadge = styled.a`
  margin-left: 5px; 
  line-height: 2
`

export const PlacedBetsContainer = styled.div``
