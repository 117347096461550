import React, { useState } from "react";
import { Card, AddAccountCard, AddCredit } from "..";
import "./UserBox.styled";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Button from "react-bootstrap/Button";
import { getCredit } from "../../utils/calculations";
import { useUser } from "../../hooks";
import {
  UserBoxContainer, BookiesContainer, BookiesHeader, EmailAndWatchBets, LeftSide, LeftSideInternal, PaymentHistory,
  RightSide, UserEmail, UserEmailText, UserProfit, WatchBets, Tags, UserTag, PopoverCustom
} from "./UserBox.styled";
import { withRouter } from 'react-router-dom'
import { TransferCredit } from "../TransferCredit/TransferCredit";
import { getUserName } from "../../utils/users";

const UserBox = ({ history, user, enabled, isAdmin, modifyUser }) => {
  const { disableEmail, enableEmail, setReadyStatus, addCredit, transferCredit } = useUser()
  const [showDisabledAccounts, setShowDisabledAccounts] = useState(false);
  const [show, setShow] = useState(false);
  const [showTransferCredit, setShowTransferCredit] = useState(false)
  const handleWatchBets = () => history.push(`/${user._id.email}/bets`);

  const handleAddBookie = () => history.push(`/bookies/${user._id.email}`);

  const isBookieEnabled = (bookie) => {
    const { enabled, activationOn } = bookie;
    if (new Date(activationOn) > new Date()) return new Date(activationOn) > new Date();
    return enabled;
  };

  const changeAccountStatus = () => {
    enabled
      ? disableEmail(user._id.email).then(() => history.push('/home/bot'))
      : enableEmail(user._id.email).then(() => history.push('/home/bot'))
  }

  const changeAccountReadyStatus = () => setReadyStatus(user._id.email, !user?.isReady).then(() => history.push('/home/bot'))

  const toggleDisabledAccounts = () => setShowDisabledAccounts(!showDisabledAccounts);

  const handleAddCredit = async values => {
    await addCredit(user._id.email, { ...values, type: 'credit' });
    setShow(false);
  };

  const handleTransferCredit = async values => {
    await transferCredit(user._id.email, values.amount);
    setShowTransferCredit(false)
  }

  const getEnabledAccounts = () => user?.bookies.filter((bookie) => isBookieEnabled(bookie));

  const getDisabledAccounts = () => user?.bookies.filter((bookie) => !isBookieEnabled(bookie));

  const getCreditForUser = () => getCredit(user);

  return (
    <UserBoxContainer>
      <UserEmail>
        <LeftSideInternal>
          <LeftSide>
            <EmailAndWatchBets>
              <UserEmailText>
                {getUserName(user)}
              </UserEmailText>
              <WatchBets className="btn" href="#" onClick={handleWatchBets}>
                Ver Apuestas
              </WatchBets>
            </EmailAndWatchBets>
            <Dropdown className="settings">
              <Dropdown.Toggle variant="secondary">
                <i className="fas fa-cog" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                { isAdmin && <Dropdown.Item eventKey="1" onSelect={() => changeAccountStatus()}>
                  {enabled ? "Desactivar cuenta" : "Activar cuenta"}
                </Dropdown.Item>
                }
                { isAdmin && <Dropdown.Item eventKey="2" onSelect={() => changeAccountReadyStatus()}>
                  { user?.isReady ? "Set Not Ready" : "Set Ready"}
                </Dropdown.Item>
                }
                {/* <Dropdown.Item eventKey="1" onSelect={() => setShowTransferCredit(true)}>
                  { 'Transferir créditos' }
                </Dropdown.Item> */}
                <Modal show={showTransferCredit} onHide={() => setShowTransferCredit(false)} centered>
                  <Modal.Header closeButton>
                    <Modal.Title>Transferir créditos</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <TransferCredit handleTransferCredit={handleTransferCredit} />
                  </Modal.Body>
                </Modal>
                {getDisabledAccounts()?.length > 0 && (
                  <Dropdown.Item eventKey="3" onSelect={() => toggleDisabledAccounts()}>
                    {showDisabledAccounts
                      ? "Ocultar cuentas desactivadas"
                      : "Mostrar cuentas desactivadas"}
                  </Dropdown.Item>
                )}
                {isAdmin && (
                  <Dropdown.Item eventKey="4" onSelect={() => setShow(true)}>
                    {"Añadir Crédito/Pago"}
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </LeftSide>
            { isAdmin && user?.isReady && 
            <Tags>
              <UserTag className="alert">
                <b>READY</b>
              </UserTag>
            </Tags>
            }
        </LeftSideInternal>
        <RightSide>
          <UserProfit>
            <p style={{ fontWeight: "bold", marginBottom: "0px" }}>
              BENEFICIO{" "}
              <a style={{ fontWeight: "normal" }}>
                {Math.round(((user?.billing || {}).profit || 0) * 100) / 100}€
              </a>
            </p>
            {getCreditForUser() ? (
              <p style={{ fontWeight: "bold", marginBottom: "0px" }}>
                {getCreditForUser().amount < 0 ? "CRÉDITO" : "A PAGAR"}
                <a style={{ fontWeight: "normal" }}>
                  <a>{" "}{Math.abs(getCreditForUser().amount)}€</a>
                </a>
                {getCreditForUser().payments.length > 0 && (
                  <OverlayTrigger
                    trigger="click"
                    key="left"
                    placement="bottom"
                    overlay={
                      <PopoverCustom id={`PopoverCustom-positioned-left`}>
                        <PopoverCustom.Title as="h3">
                          Información sobre pagos
                        </PopoverCustom.Title>
                        <PopoverCustom.Content>
                          <p>
                            <strong>Porcentaje Sobre beneficio</strong>{" "}
                            {getCreditForUser().rate}%
                          </p>
                          <a>
                            <strong>Historial de pagos</strong>
                          </a>
                          {getCreditForUser().payments.map((payment) => (
                            <PaymentHistory>
                              Pagados <b>{payment.amount.toFixed(2)}€</b> el{" "}
                              <b>
                                {new Date(
                                  payment.timestamp
                                ).toLocaleDateString()}
                              </b>
                            </PaymentHistory>
                          ))}
                        </PopoverCustom.Content>
                      </PopoverCustom>
                    }
                  >
                    <Button style={{ marginLeft: "5px" }} variant="secondary">
                      <i className="fas fa-info-circle" />
                    </Button>
                  </OverlayTrigger>
                )}
              </p>
            ) : ("")}
          </UserProfit>
        </RightSide>
      </UserEmail>
      <BookiesContainer>
        {getEnabledAccounts()?.map((bookie) => (
          <Card
            key={`${bookie.name}-${bookie.email}-${bookie.username}`}
            isAdmin={isAdmin}
            modifyUser={modifyUser}
            email={user._id.email}
            name={bookie.name}
            username={bookie.username}
            enabled={isBookieEnabled(bookie)}
            balance={bookie.balance}
            history={history}
            bookie={bookie}
            status={bookie.status}
          />
        ))}
        <AddAccountCard onClick={handleAddBookie} />
      </BookiesContainer>
      {showDisabledAccounts && (
        <div>
          <BookiesHeader>Desactivadas</BookiesHeader>
          <BookiesContainer>
            {getDisabledAccounts().map((bookie) => (
              <Card
                key={`${bookie.name}-${bookie.email}-${bookie.username}`}
                isAdmin={isAdmin}
                modifyUser={modifyUser}
                email={user._id.email}
                name={bookie.name}
                username={bookie.username}
                enabled={isBookieEnabled(bookie)}
                balance={bookie.balance}
                history={history}
                bookie={bookie}
              />
            ))}
          </BookiesContainer>
        </div>
      )}
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Añadir Crédito/Pago</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddCredit handleAddCredit={handleAddCredit} />
        </Modal.Body>
      </Modal>
    </UserBoxContainer>
  );
};

export default withRouter(UserBox)
